/* .e-toolbar-right {
  display: none !important;
} */
.e-header-popup {
  right: 0 !important;
  left: unset !important;
  top: unset !important;
}

#_recurrence_editor {
  display: none !important;
}